/* eslint-disable camelcase */
import {
    FieldOrigin,
    FormWrapper,
    AssociatedValidation,
    ChildrenRenderProps,
    FormContextInterface,
    evaluateConditions,
} from '@mercell/form-react';
import { AnySchema, ValidationError } from 'yup';
import parseFormDefinitionWrapper from '../../shared/mappedFormSchema';
import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetchForm, {
    FormDataStateInterface,
} from '../../hooks/formHooks/useFetchForm';
import {
    Link,
    useNavigate,
    useOutletContext,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import FormError from '../../components/FormError';
import { useAutoSaveForm } from '../../hooks/formHooks/useAutoSaveForm';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { ReadOnlyComponent } from '../../components/ReadOnlyComponent';
import NotFoundPage from '../NotFound/NotFoundPage';
import { useStoreContext } from '../../state';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { scrollIntoView } from '../../shared/scrollIntoView';
import { backendErrorSubmitMutator } from '../../shared/validationModule/backendErrorSubmitMutator';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import { OutletFormContextInterface } from '../../types/outletInterface';
import { lingualFieldSelectionMutator } from '../../shared/validationModule/lingualFieldSelectionMutator';
import { useLingualFieldSelectionMutator } from '../../hooks/formHooks/useLingualFieldSelectionMutator';
import { useBackendErrorSubmitMutator } from '../../hooks/formHooks/useBackendErrorSubmitMutator';
import { PreviewElementStyle } from '../../shared/getStyleClasses';
import { lingualTextLanguageSelectorMutator } from '../../shared/utilities/lingualTextLanguageSelectorMutator';
import { useLingualTextLanguageSelectorMutator } from '../../hooks/formHooks/useLingualTextLanguageSelectorMutator';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormStatus } from '../../types/generated/formMenuResult';
import Button from '@mercell/button-react';
import BackNavigation from '../../types/backNavigation';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
    setNoticeStatus,
    setTenderName,
    setIsLoadingForm,
} from '../../redux/storeSlice';

const FormContainer: FC = memo(() => {
    const { featureFlagUseUtcDatesOnly, featureFlagEnableTextAreaCounter } =
        useFlags();
    const navigate = useNavigate();
    const {
        setBackendValidationErrors,
        backendValidationErrors,
        setHotReloadOfMenu,
        isFormPreview = false,
        navigationTitle,
        parentElementTitleKey,
        currentSelectedSection,
        setShowRevalidateMessage,
        noticeTimeZone: noticeTimeZoneFromSection,
    } = useOutletContext<OutletFormContextInterface>();
    const { renderedFieldsWithErrors } = backendValidationErrors;
    const {
        formId,
        sectionId,
        arrayPath,
        parentSectionId,
        parentArrayElementId,
        parentArrayPath,
        arrayElementId,
    } = useParams<{
        formId: string;
        sectionId: string;
        arrayPath?: string;
        parentSectionId?: string;
        parentArrayElementId?: string;
        parentArrayPath?: string;
        arrayElementId?: string;
    }>();

    const [searchParams, setSearchParams] = useSearchParams();

    const {
        state: { tenderName: tenderNameState },
        dispatch,
    } = useStoreContext();

    const reduxNoticeStatus = useAppSelector(
        (state) => state.store.noticeStatus
    );
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const reduxLocale = useAppSelector((state) => state.store.locale);
    const reduxTenderName = useAppSelector((state) => state.store.tenderName);
    const reduxDispatch = useAppDispatch();

    const language = getFormLanguage();
    const sectionPath =
        parentArrayElementId && sectionId
            ? `${parentArrayElementId}.${sectionId}`
            : sectionId;
    const {
        formData,
        isErrorFetchingForm,
        isLoadingForm,
        tenderName,
        mutateFetchedForm,
    } = useFetchForm(formId, sectionPath);
    // list.cpv should be fetched by translation list (should be removed later on from here)
    const { t } = useTranslation(
        [
            'form-content',
            'list.cpv',
            'list.organisation-role',
            'list.organisation-subrole',
            'rules',
        ].concat(formData?.codelists ?? [])
    );

    const setupBackNavigation = () => {
        let backNavigation: BackNavigation = { path: '' };
        if (parentSectionId && !arrayElementId && parentElementTitleKey) {
            backNavigation = {
                path: `/form/${formId}/${parentSectionId}/${parentArrayPath}`,
            };
        }
        if (parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}`,
            };
        }
        if (!parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/${sectionId}/${arrayPath}`,
            };
        }
        return backNavigation;
    };

    const backNavigation = setupBackNavigation();

    const formPageTitle = currentSelectedSection?.translationKey
        ? t(currentSelectedSection?.translationKey)
        : '';

    function resetNoticeStatusToDraft() {
        const noticeStatusPayload = {
            value: 0,
            status: t(`list.audit-log-status:${FormStatus[0]}`),
        };

        if (reduxNoticeStatus?.value !== 0) {
            reduxDispatch(setNoticeStatus(noticeStatusPayload));
            setShowRevalidateMessage(true);
        }
    }

    const { onAutoSave } = useAutoSaveForm<FormDataStateInterface>(
        `form/save/${formId}/${language}/${sectionId ? `${sectionPath}` : ''}`,
        formData,
        mutateFetchedForm,
        setHotReloadOfMenu,
        resetNoticeStatusToDraft
    );

    useEffect(() => {
        if (isLoadingForm) {
            reduxDispatch(setIsLoadingForm(true));
        } else {
            reduxDispatch(setIsLoadingForm(false));
        }
    }, [isLoadingForm, reduxDispatch]);

    useEffect(() => {
        if (tenderName && reduxTenderName !== tenderName) {
            reduxDispatch(setTenderName(tenderName ?? ''));
        }
    }, [dispatch, tenderNameState, tenderName, reduxTenderName, reduxDispatch]);

    useEffect(() => {
        const fieldName = searchParams.get('clicked') || '';
        const field = renderedFieldsWithErrors[fieldName];
        if (fieldName && field) {
            scrollIntoView(
                renderedFieldsWithErrors[fieldName],
                searchParams,
                setSearchParams
            );
        }
    }, [setSearchParams, renderedFieldsWithErrors, searchParams]);

    if (!formId) {
        return null;
    }
    if (isLoadingForm) {
        return (
            <div className="h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }
    if (isErrorFetchingForm) {
        return (
            <NotFoundPage
                wrapperClass="mt-24"
                errorCode={isErrorFetchingForm.status.toString()}
            />
        );
    }
    if (!formData || !formData.formDefinition.length) {
        return (
            <div className="h-full flex items-center justify-center">
                No form in DB
            </div>
        );
    }

    const { formDefinition, errors, values, noticeTimeZone } = formData;
    const schema = parseFormDefinitionWrapper(
        formDefinition,
        t,
        isFormPreview,
        reduxDateFormat,
        false,
        reduxLocale,
        featureFlagUseUtcDatesOnly,
        noticeTimeZone ?? noticeTimeZoneFromSection,
        featureFlagEnableTextAreaCounter
    );
    const addUniqueErrors = (error: string[] | undefined) => {
        if (!error) return;
        const uniqueErrors = error?.filter(
            (v, i, self) =>
                // It returns the index of the first
                // instance of each value
                i === self.indexOf(v)
        );

        return uniqueErrors ?? [];
    };

    const validationCheck: FormContextInterface['validationCallback'] = async (
        fieldValue: any,
        fieldValidation: any,
        allValues?: object,
        associatedValidationBasedOnOtherFieldValues?: AssociatedValidation,
        reApplyRequiredIndicator?: React.Dispatch<React.SetStateAction<boolean>>
    ): Promise<string> => {
        const arrayOfErrors: string[] = [];
        if (associatedValidationBasedOnOtherFieldValues?.conditionalFields) {
            const result = evaluateConditions(
                associatedValidationBasedOnOtherFieldValues.conditionalFields,
                allValues
            );

            if (
                result &&
                !associatedValidationBasedOnOtherFieldValues.rules &&
                associatedValidationBasedOnOtherFieldValues.message
            ) {
                arrayOfErrors.push(
                    associatedValidationBasedOnOtherFieldValues.message
                );
            } else if (
                result &&
                associatedValidationBasedOnOtherFieldValues.rules
            ) {
                try {
                    (
                        associatedValidationBasedOnOtherFieldValues.rules as AnySchema
                    )?.validateSync(fieldValue, {
                        abortEarly: false,
                    });
                } catch (err: any) {
                    err.inner.forEach((e: ValidationError) => {
                        arrayOfErrors.push(e.message);
                    });
                    if (
                        associatedValidationBasedOnOtherFieldValues.reApplyRequiredIndicator &&
                        reApplyRequiredIndicator
                    ) {
                        reApplyRequiredIndicator(true);
                    }
                }
            } else if (
                !result &&
                associatedValidationBasedOnOtherFieldValues.rules
            ) {
                if (
                    associatedValidationBasedOnOtherFieldValues.reApplyRequiredIndicator &&
                    reApplyRequiredIndicator
                ) {
                    reApplyRequiredIndicator(false);
                }
            }
        }
        try {
            fieldValidation?.validateSync(fieldValue, {
                abortEarly: false,
            });
        } catch (err: any) {
            err.inner.forEach((e: ValidationError) => {
                arrayOfErrors.push(e.message);
            });
        }
        return addUniqueErrors(arrayOfErrors)?.join('\n') ?? '';
    };

    return (
        <div>
            {parentSectionId && (
                <>
                    <Breadcrumbs className="ml-4 mt-1 mb-4">
                        <Link
                            to={`/form/${formId}/${parentSectionId}/${parentArrayPath}`}
                        >
                            {t(parentElementTitleKey ?? '')}
                        </Link>
                        <Link
                            to={window.location.pathname}
                            className="pointer-events-none"
                        >
                            {formPageTitle}
                        </Link>
                    </Breadcrumbs>
                    <div className="flex items-center text-h2 ml-4 mb-4 mt-1 pb-3 border-alto border-b-2">
                        {navigationTitle ?? t('form-content:NewItem')}
                    </div>
                </>
            )}
            {!isFormPreview &&
                errors.formErrors?.map((error: any) => (
                    <FormError error={error} key={error} />
                ))}
            {formDefinition?.length > 0 && values && (
                <>
                    <FormWrapper
                        onSubmit={() => undefined}
                        onAutoSave={!isFormPreview ? onAutoSave : undefined}
                        skipFieldsOnAutoSave={['LingualTextLanguageSelector']}
                        validationCallback={validationCheck}
                        schema={schema}
                        formLabelProperties={{
                            className: 'text-h1 font-bold',
                        }}
                        subscription={{
                            dirtySinceLastSubmit: true,
                        }}
                        readOnlyFieldRender={(
                            content: any,
                            name: string,
                            restReadOnlyProperties,
                            fieldOrigin?: FieldOrigin,
                            elementProperties?: React.DetailedHTMLProps<
                                React.HTMLAttributes<HTMLElement>,
                                HTMLElement
                            >,
                            fieldErrors?: string
                        ) => (
                            <ReadOnlyComponent
                                fieldErrors={fieldErrors}
                                content={content}
                                name={name}
                                isFormPreview={isFormPreview}
                                readOnlyProperties={restReadOnlyProperties}
                                fieldOrigin={fieldOrigin}
                                elementProperties={elementProperties}
                                t={t}
                                locale={reduxLocale}
                                noticeTimeZone={noticeTimeZone}
                            />
                        )}
                        previewElementStyle={PreviewElementStyle}
                        mutators={{
                            backendErrorSubmitMutator,
                            lingualFieldSelectionMutator,
                            lingualTextLanguageSelectorMutator,
                        }}
                        autoSaveThrottleDelay={1000}
                        formTitle="" // "React Final Form"
                        initialValue={values}
                        showErrorsOnInitialRender
                        parentSectionId={parentSectionId}
                        sectionId={sectionId}
                        arrayElementId={arrayElementId}
                        parentArrayElementId={parentArrayElementId}
                        backendValidationErrors={backendValidationErrors}
                        childrenRender={Render}
                        setBackendValidationErrors={setBackendValidationErrors}
                    />
                    {(arrayElementId || parentSectionId) && (
                        <div className="flex flex-row justify-end pr-4 pt-8">
                            <Button
                                scheme="secondary"
                                onClick={() => navigate(backNavigation.path)}
                            >
                                {t('form-content:Close')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

const Render = ({
    mutators,
    setBackendValidationErrors,
    backendValidationErrors,
    parentSectionId,
    sectionId,
    arrayElementId,
    parentArrayElementId,
}: ChildrenRenderProps) => {
    useLingualFieldSelectionMutator(
        mutators.lingualFieldSelectionMutator,
        setBackendValidationErrors
    );
    useBackendErrorSubmitMutator(
        mutators.backendErrorSubmitMutator,
        backendValidationErrors,
        setBackendValidationErrors,
        parentSectionId || '',
        sectionId || '',
        arrayElementId || '',
        parentArrayElementId || ''
    );
    useLingualTextLanguageSelectorMutator(
        mutators.lingualTextLanguageSelectorMutator
    );
    return null;
};

FormContainer.displayName = 'FormContainer';
FormContainer.whyDidYouRender = true;
export default FormContainer;
